import React from 'react';
import ReactDOM from 'react-dom';
import Analytics from 'analytics';
// @ts-ignore
import segmentPlugin from '@analytics/segment';
import App from './App';
import config from './config';

// we're not doing any segment event tracking right now
// so we only need to initialize Segment to load drift and fullstory
const { SEGMENT_KEY } = config;
Analytics({
  app: 'supplies-rfq',
  plugins: [
    segmentPlugin({
      writeKey: SEGMENT_KEY,
    }),
  ],
});

document.location = "https://supplies.xometry.com";
